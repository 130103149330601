<template>
  <v-container>
    <v-card flat :loading="loading" :disabled="loading" class="mt-4">
      <v-card-title class="subtitle-1 pa-0">
        {{ $tc("pages.empresa") }}
      </v-card-title>
      <v-divider></v-divider>
      <v-row>
        <!-- Nome da Empresa -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_nome"
            :label="$tc('global.nome')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Razão Social -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_razaosocial"
            :label="$tc('empresas.razaosocial')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Documento -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_documento"
            :label="$tc('global.documento')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Endereço -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_endereco"
            :label="$tc('columns.endereco')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Cidade -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_cidade"
            :label="$tc('global.cidade')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Telefone -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_telefone"
            :label="$tc('global.telefone')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Celular -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_celular"
            :label="$tc('columns.celular')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Whatsapp -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_whatsapp"
            label="Whatsapp"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Site -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_wwww"
            :label="$tc('empresas.site')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Email -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_email"
            label="Email"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-card-title class="subtitle-1 pa-0 mt-6">
        {{ $tc("empresas.responsavel") }}
      </v-card-title>
      <v-divider></v-divider>

      <v-row>
        <!-- Responsavel -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_responsavel"
            :label="$tc('empresas.responsavel')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Documento Responsavel -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_responsavel_documento"
            :label="$tc('global.documento') + ' ' + $tc('empresas.responsavel')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Email Responsavel -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_responsavel_email"
            :label="'Email ' + $tc('empresas.responsavel')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Celular Responsavel -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_responsavel_celular"
            :label="$tc('columns.celular') + ' ' + $tc('empresas.responsavel')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Whatsapp Responsavel -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="empresa.empresa_responsavel_whatsapp"
            :label="'Whatsapp ' + $tc('empresas.responsavel')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn
        v-if="vueEditTocsFilho"
        @click.stop="add ? addTocs() : updateTocs()"
        :disabled="disable"
        fixed
        right
        bottom
        fab
        dark
        color="buttons"
        :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
      >
        <v-icon>save</v-icon>
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
import { update, add } from "@/services/api/tocs.api.js";
import { mapGetters } from "vuex";

export default {
  name: "Campos",
  props: {
    item: {
      type: Object,
    },
    add: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      empresa: {},
      loading: false,
    };
  },
  computed: {
    disable() {
      let result = true;
      if (this.empresa.empresa_nome && this.empresa.empresa_documento) {
        result = false;
      }
      return result;
    },
    ...mapGetters("Componentes", ["getAccess"]),
    vueEditTocsFilho() {
      return this.getAccess("vueTocsFilho", "editar");
    },
  },
  watch: {
    item() {
      if (this.item && !this.add) this.empresa = this.item;
    },
  },
  methods: {
    updateTocs() {
      this.$Progress.start();
      this.loading = true;
      update(this.empresa.id, this.empresa)
        .then((response) => {
          this.$toast.success(this.$tc("msg.atualizadosucesso"));
          this.$Progress.finish();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
          this.loading = false;
        });
    },
    addTocs() {
      this.$Progress.start();
      this.loading = true;
      add(this.empresa)
        .then((response) => {
          this.$toast.success(this.$tc("msg.criadosucesso"));
          this.empresa = {};
          this.$Progress.finish();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.item && !this.add) {
      this.empresa = this.item;
    }
  },
};
</script>

<style></style>
